import React, { lazy, Suspense } from "react";
// import Navbar from "./Navbar.jsx";
import Header from "./Header.jsx";
import Info from "../Info.jsx";
import Notice from "../Notice.jsx";
import Test from "../Test.jsx";
// import Assesment from "../Assessment.jsx";
// import Quizz from "../Quizz";
// import Evaluation from "../Evaluation";
import Revolutionary from "../Revolutionary.jsx";
// import OnlineExamService from "../OnlineExamService.jsx";
import StudentWrapper from "../StudentWrapper.jsx";
import Wrapper from "../Wrapper.jsx";
import {useState, useEffect } from "react";
const Assesment = lazy(() => import("../Assessment.jsx"));
const Navbar = lazy(() => import("./Navbar.jsx"));

const Main =() =>{
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

const initialUser = localStorage.getItem("token") || null;
const [user, setUser] = useState(initialUser !== "null" ? initialUser : null);

useEffect(() => {

  const token = localStorage.getItem("token");
  setUser(token || null);
}, [initialUser]);

  return (<>
    <Suspense fallback={<div>Loading...</div>}>
     
      <main>


        <Header />
        <Info />
        <Notice />
        <Test />
        {
          user ?
            <>

            </> :
            <>
              <Assesment />

            </>
        }
        {/* <Quizz/> */}
        {/* <Evaluation/> */}
        <Revolutionary />
        {/* <OnlineExamService/> */}
        <StudentWrapper />
        <Wrapper />

      </main>
   
    </Suspense>
  </>
  )
}
export default Main;


