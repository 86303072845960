import React, { useState } from 'react'
import Header from './Header/Header'
import Sidebar from './Sidebar/Sidebar'
import { Outlet } from 'react-router'

const AdminLayout = () => {
    // responsive menu state
    const [resmenu, setresMenu] = useState(false); 

    return (
        <main className='ins-container d-flex'>
            <Sidebar resmenu={resmenu} setresMenu={setresMenu} />
                
            <div className="col instructions-top-containe">
                <Header resmenu={resmenu} setresMenu={setresMenu} /> 
                <div className="p-4 outlet__content"> 
                    <Outlet />
                </div> 
                    
            </div> 

        </main>
    )
}

export default AdminLayout