import { logo, smlogo, profile01 } from "../../imagepath";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useRef, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"; 
import { useState } from "react"; 
import styles from "./Header.module.css";
import { loginReducer, logoutReducer } from "../../../components/Auth/Login/LoginSlice";
import axios from "axios";
import { SERVER_URL } from "../../../config";
const BASE_URL = SERVER_URL;
const Header = ({resmenu, setresMenu}) => {

  // const data = useSelector((state) => state.userProfile.data);
  // const [ userName , setUserName ] = useState('');

  


  const dispatch = useDispatch();
  // const getAllList = useCallback(async () => {
  //   dispatch(
  //     profileReducer({
  //     })
  //   );
  // }, [dispatch]);

  // useEffect(() => {
  //   let mounted = true;

  //   if (mounted) {
  //     getAllList();
  //   }

  //   return () => {
  //     mounted = false;
  //   };
  // }, [getAllList]);
  //   useLayoutEffect(() => {

  //     // getAllList();
  // }, [data.full_name]);

  const navigate = useNavigate();

  const initialUser = localStorage.getItem("token") || null;
  const initialUserName = localStorage.getItem("user_name") || null;
  const [user, setUser] = useState(initialUser);
  const [userName, setUserName] = useState(initialUserName);

  useEffect(() => {

    const token = localStorage.getItem("token");
    const UserName = localStorage.getItem("user_name");
    setUser(token || null);
    setUserName(UserName || null);

  }, [initialUser]);


  const [previewUrl, setPreviewUrl] = useState(null);
  const [profilePicName, setProfilePicName] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError('User is not authenticated');
      return;
    }

    axios.get(`${BASE_URL}/student-details`, {
      headers: {
        'remember-token': token,
      }
    })
      .then(response => {

        if (response.data.status) {

          setPreviewUrl(response.data.data.profile);

          let profile = response.data.data.profile;
          let imageName = profile.split('/').pop();
          setProfilePicName(imageName);
          setError(''); // Clear any previous errors
         }
      })
      .catch(error => {
        console.error('There was an error fetching the data!', error);
        setError('No Data Found.');
      });
  }, []);


  // const logout = async () => {
  //   localStorage.removeItem("token");
  //   localStorage.removeItem("user_name");
  //   dispatch(logout());
  //   setTimeout(() => {
  //     // Get updated values
  //     const token = localStorage.getItem("token");
  //     const userName = localStorage.getItem("user_name");


  //     // Check if both token and userName are null
  //     if (token === null && userName === null) {
  //       // Navigate to home page
  //       navigate("/");
  //     }

  //     setUser(null);
  //     setUserName(null);
  //   }, 0); 
  // };

  const logout = async () => {
    try {
      // Remove token and user_name from local storage
      dispatch(logoutReducer());
      localStorage.removeItem("token");
      localStorage.removeItem("user_name");



      setTimeout(() => {
        const token = localStorage.getItem("token");
        const userName = localStorage.getItem("user_name");

        if (token === null && userName === null) {
          navigate("/");
        }

        setUser(null);
        setUserName(null);
      }, 0);
    } catch (error) {
      console.error("Error during logout process", error);
    }
  };

  const routerPath = useLocation();

 

  return (
    <>
      <div className={`${styles.header__wrapper} ${routerPath.pathname == '/result' ? 'ps-0' : ' ps-3'} bg-white pe-3 d-flex align-items-center shadow-sm`}> 
        <div className=" d-flex align-items-center justify-content-between w-100">
          <div className="d-inline-flex align-items-center"> 
            {
              routerPath.pathname == '/result' && ( 
              <Link to="/admin-dashboard" className={`bg-white d-flex align-items-center justify-content-center px-3  me-3 ${styles.logo__box}`}>
                <img src="/assets/images/digital-edu-park-logo.webp" className={`img-fluid ${styles.header__logo}`} alt="Logo" />
              </Link>
              )
            }
            <h2 className="text-white mb-0">
              {routerPath.pathname == '/admin-dashboard' && <>Dashboard</>}
              {routerPath.pathname == '/quiz' && <>Quiz List</>}
              {routerPath.pathname == '/quize-details/:id' && <>Result</>}
             
            </h2> 
          </div>
          <ul className={`nav `}> 
            {/* <Link to="/admin-dashboard" className="student-result-heading"> Dashboard</Link> */} 
            <li className="nav-item dropdown">
              <Link href="#" className={`dropdown-toggle `} data-bs-toggle="dropdown">
                <div className="d-inline-flex align-items-center gap-2 text-dark"> 
                  <span>Hi {user ? <> {userName ?? ""} </> : <> {""}</>} </span>
                  <img className="rounded-circle" src={previewUrl ?? profile01} width={50} height={50} alt="Ryan Taylor" />
                </div>
              </Link>
              <div className="dropdown-menu">
                {/* <div className={dashboardStyle['user-header']}>
                <div className={`${dashboardStyle['avatar']} ${dashboardStyle['avatar-sm']}`}>
                  <img src={profile01} alt="User Image" className="avatar-img rounded-circle"/>
                </div>
                <div className={dashboardStyle['user-text']}>
                <h6>Hi 
                {user ? <> {data.user_name} </> : <> {""}</>} </h6>
                  <p className={`${dashboardStyle['text-muted']} mb-0`}>Administrator</p>
                </div>
              </div> */}
                    {/* <Link className={dashboardStyle['dropdown-item']} href="profile.html">My Profile</Link>
              <Link className={dashboardStyle['dropdown-item']} href="inbox.html">Inbox</Link> */}
                <Link to="/" className='dropdown-item' onClick={logout}>Logout</Link>
              </div>
            </li>
            <li className="nav-item ms-3 d-xl-none">
              <button type="button" onClick={()=>setresMenu(!resmenu)} className="btn text-white p-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24"><path fill="white" d="M3.75 5.25a.75.75 0 0 0 0 1.5h16.5a.75.75 0 0 0 0-1.5zm0 6a.75.75 0 0 0 0 1.5h16.5a.75.75 0 0 0 0-1.5zm0 6a.75.75 0 0 0 0 1.5h16.5a.75.75 0 0 0 0-1.5z"></path></svg>
              </button>
            </li>
          </ul>
        </div>

      </div>
    </>
  )
}
export default Header;
