import React from "react";
import EasyLearning from "../asset/images/icons/easylearning.svg";
import InterActiveInterface from "../asset/images/icons/interactive-interface.svg";
import TestSoftware from "../asset/images/icons/test-software.svg";
import ExceptionSupport from "../asset/images/icons/exception-support.svg";
import SmartSubscription from "../asset/images/icons/smart-subscriptiont.svg";
import ActiveAccessibility from "../asset/images/icons/activeaccessibility.svg";
import { Link } from "react-router-dom";

const Revolutionary = () => {

	return (<>
		{/* <section className="py-5 revolutionary__wrapper text-center position-relative">
			<div className="container py-sm-4">
				<h2 className={`text-center fs-1 fw-semibold text-dark mb-3`}>A Revolutionary Step in the Online Examination System</h2>
				<p>It is an exceptional online test platform for conducting online exams and solving all your preparation doubts. It is a smooth online examination system to use and enhances the flow of information.</p>

				<div className="row g-4 g-lg-5 mt-4 row-cols-1 row-cols-sm-2  row-cols-md-3 row-cols-lg-3">
					<div>
						<div className="info__card">
							<figure className="icon__box bg-blue mx-auto rounded-circle d-flex justify-content-center align-items-center">
								<img src={EasyLearning} alt="Simple to learn" width="40" height="43" className="img-fluid" loading="lazy" />
							</figure>
							<h3 className="fs-5 fw-medium text-dark">Simple to learn</h3>
							<p className="text-54 small">One stop solution for online examination and assessment. It would solve all your problems in preparation for the exam.</p>
						</div>
					</div>
					<div>
						<div className="info__card">
							<figure className="icon__box bg-primary mx-auto rounded-circle d-flex justify-content-center align-items-center">
								<img
									src={InterActiveInterface}
									alt="Interactive Interface" width="40" height="43"
									className="img-fluid" loading="lazy" />
							</figure>
							<h3 className="fs-5 fw-medium text-dark">Interactive Interface</h3>
							<p className="text-54 small">Easy registration, click away tricks, and simple test creation facilitated by online exam software. Signing in and concurring.</p>
						</div>
					</div>
					<div>
						<div className="info__card">
							<figure className="icon__box bg-green mx-auto rounded-circle d-flex justify-content-center align-items-center">
								<img
									src={TestSoftware}
									alt="software" width="40" height="43"
									className="img-fluid" loading="lazy"
								/>
							</figure>
							<h3 className="fs-5 fw-medium text-dark">Test software with AdvancedReporting System</h3>
							<p className="text-54 small">Online exam software ensures fast result generation, digital evaluation, sharing feedback.</p>
						</div>
					</div>
					<div>
						<div className="info__card">
							<figure className="icon__box bg-primary mx-auto rounded-circle d-flex justify-content-center align-items-center">
								<img
									src={ExceptionSupport}
									alt="Exception Support" width="40" height="43"
									className="img-fluid" loading="lazy"
								/>
							</figure>
							<h3 className="fs-5 fw-medium text-dark">Exception Support</h3>
							<p className="text-54 small">We offer exceptional support for the exam platform to your request. Our dedicated professionals work round the clock to work on your queries.</p>
						</div>
					</div>
					<div>
						<div className="info__card">
							<figure className="icon__box bg-green mx-auto rounded-circle d-flex justify-content-center align-items-center">
								<img
									src={SmartSubscription}
									alt="Smart Subscription" width="40" height="43"
									className="img-fluid" loading="lazy"
								/>
							</figure>
							<h3 className="fs-5 fw-medium text-dark">Smart Subscription</h3>
							<p className="text-54 small">Subscription according to your reference for the use of an online test platform.</p>
						</div>
					</div>
					<div>
						<div className="info__card">
							<figure className="icon__box bg-blue mx-auto rounded-circle d-flex justify-content-center align-items-center">
								<img
									src={ActiveAccessibility}
									alt="Dynamic Accessibility" width="57" height="42"
									className="img-fluid" loading="lazy"
								/>
							</figure>
							<h3 className="fs-5 fw-medium text-dark">Dynamic Accessibility</h3>
							<p className="text-54 small">Anywhere and anytime practice of online examination system. The exam platform helps in designing and assigningan online exam...</p>
						</div>
					</div>
				</div>
				<div className="mt-4 mt-lg-5">
					<Link to="#" className="btn btn-primary blue animate-btn rounded-0" data-text="EXPLORE WITH OUR EXPERTS" aria-label="EXPLORE WITH OUR EXPERTS">
						<span>EXPLORE WITH OUR EXPERTS </span>
					</Link>
				</div>
			</div>
		</section> */}

		<section className="py-5 revolutionary__wrapper text-center position-relative">
			<div className="container py-sm-4">
				<p>Welcome to Digital Edupark, a cutting-edge online assessment platform designed to empower students and young aspirants’ learning and growth. Our team of educators and experts collaborate to create self-evaluative and engaging content, fostering a comprehensive learning experience worldwide.</p>
				<h2 className={`text-center fs-1 fw-semibold text-dark mb-3`}>A Revolutionary Step in the Online Examination System</h2>
				<p>A pioneering online assessment platform, revolutionizing the way exams are taken, graded, and analyzed for seamless learning.  With our user-friendly interface, you can create, administer, and grade your online tests, quizzes, and exams with ease. Take advantage of our advanced features, including:</p>
				<ul style={{ listStyleType: 'disc', textAlign: 'left', paddingRight: '20px', margin: '0', padding: '0 20px' }}>
					<li>•	Customizable question types and formats</li>
					<li>•	Robust grading and scoring options</li>
					<li>•	Real-time results and analytics</li>
					<li>•	Integration with popular learning management systems</li>
				</ul>
		



				<div className="row g-4 g-lg-5 mt-4 row-cols-1 row-cols-sm-2  row-cols-md-3 row-cols-lg-3">
					<div>
						<div className="info__card">
							<figure className="icon__box bg-blue mx-auto rounded-circle d-flex justify-content-center align-items-center">
								<img src={EasyLearning} alt="Simple to learn" width="40" height="43" className="img-fluid" loading="lazy" />
							</figure>
							<h3 className="fs-5 fw-medium text-dark">Simple to Learn</h3>
							<p className="text-54 small">Online examination assessment: a convenient and efficient way to evaluate student knowledge and skills, with immediate results.</p>
						</div>
					</div>
					<div>
						<div className="info__card">
							<figure className="icon__box bg-primary mx-auto rounded-circle d-flex justify-content-center align-items-center">
								<img
									src={InterActiveInterface}
									alt="Interactive Interface" width="40" height="43"
									className="img-fluid" loading="lazy" />
							</figure>
							<h3 className="fs-5 fw-medium text-dark">Interactive Interface</h3>
							<p className="text-54 small">User-friendly platform with intuitive interface, easy to navigate and score, with instant results and detailed analytics for assessment.</p>
						</div>
					</div>
					<div>
						<div className="info__card">
							<figure className="icon__box bg-green mx-auto rounded-circle d-flex justify-content-center align-items-center">
								<img
									src={TestSoftware}
									alt="software" width="40" height="43"
									className="img-fluid" loading="lazy"
								/>
							</figure>
							<h3 className="fs-5 fw-medium text-dark">Software with Advanced Reporting System</h3>
							<p className="text-54 small">Advanced reporting system for online examination assessment, providing detailed analysis and insights for educators and institutions.</p>
						</div>
					</div>
					<div>
						<div className="info__card">
							<figure className="icon__box bg-primary mx-auto rounded-circle d-flex justify-content-center align-items-center">
								<img
									src={ExceptionSupport}
									alt="Exception Support" width="40" height="43"
									className="img-fluid" loading="lazy"
								/>
							</figure>
							<h3 className="fs-5 fw-medium text-dark">Exception Support</h3>
							<p className="text-54 small">Exemplary online examination platform offering comprehensive support system, ensuring seamless navigation, and timely assistance to students.</p>
						</div>
					</div>
					<div>
						<div className="info__card">
							<figure className="icon__box bg-green mx-auto rounded-circle d-flex justify-content-center align-items-center">
								<img
									src={SmartSubscription}
									alt="Smart Subscription" width="40" height="43"
									className="img-fluid" loading="lazy"
								/>
							</figure>
							<h3 className="fs-5 fw-medium text-dark">Smart Subscription</h3>
							<p className="text-54 small">Subscription according to your reference for the use of an online test platform.</p>
						</div>
					</div>
					<div>
						<div className="info__card">
							<figure className="icon__box bg-blue mx-auto rounded-circle d-flex justify-content-center align-items-center">
								<img
									src={ActiveAccessibility}
									alt="Dynamic Accessibility" width="57" height="42"
									className="img-fluid" loading="lazy"
								/>
							</figure>
							<h3 className="fs-5 fw-medium text-dark">Dynamic Accessibility</h3>
							<p className="text-54 small">Web-based examination platform allows students to access and complete assessments from anywhere, at any time, on any device.</p>
						</div>
					</div>
				</div>
				<div className="mt-4 mt-lg-5">
					<Link to="#" className="btn btn-primary blue animate-btn rounded-0" data-text="EXPLORE WITH OUR EXPERTS" aria-label="EXPLORE WITH OUR EXPERTS">
						<span>EXPLORE WITH OUR EXPERTS </span>
					</Link>
				</div>
			</div>
		</section>
	</>)
}
export default Revolutionary;