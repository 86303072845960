import React, { lazy, Suspense } from "react";
// import Navbar from "./layouts/Navbar";
// import Footer from "./layouts/Footer";
// import AboutOnlineExam from "./about/AboutOnlineExam";
// import StudentWrapper from "./StudentWrapper";
// import Clasess from "./class/classes";
// import ClassHeader from "./class/ClassHeader";
// import SubjectClass from "./class/SubjectClass";
import "./ClassComponent.css";
import { useEffect } from "react";

const SubjectClass = lazy(() => import("./class/SubjectClass.jsx"));
const Navbar = lazy(() => import("./layouts/Navbar.jsx"));
const Footer = lazy(() => import("./layouts/Footer.jsx"));
const AboutOnlineExam = lazy(() => import("./about/AboutOnlineExam.jsx"));
const StudentWrapper = lazy(() => import("./StudentWrapper.jsx"));
const Clasess = lazy(() => import("./class/classes.jsx"));
const ClassHeader = lazy(() => import("./class/ClassHeader.jsx"));
const ClassComponent = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
  });
}, []);
  return (
    <>
    <Suspense fallback={<div>Loading...</div>}>
      {/* <Navbar /> */}
      <ClassHeader />
      
      <SubjectClass />
      <AboutOnlineExam />
      <StudentWrapper />
      <Clasess />
      <Footer /> 
      </Suspense>

    </>
  );
}
export default ClassComponent;