import React from 'react'
import { Formik } from "formik";
import * as Yup from "yup";
import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import './Auth/Login/login.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PropagateLoader } from 'react-spinners';

import { SERVER_URL } from '../config';
const BASE_URL = SERVER_URL;
function SuggestionFormModal() {
    const [category, setCategory] = useState([]);
    let [success, setSuccess] = useState("");
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState("");

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        setLoader(true);
        try {
            const response = await axios.post(`${BASE_URL}/add-suggestion`, {
                name: values.name,
                mobile_no: values.mobile_number,
                course: values.category,
                profession: values.profession,
                suggestion: values.suggestion,
            });

            const data = response?.data;
            if (data?.status === true) {
                setSuccess(response?.data?.sucess?.message)
                setTimeout(() => {
                    const closeButton = document.getElementById('closeSuggestionsButton');
                    if (closeButton) {
                        closeButton.click();
                    }
                    setSuccess("");
                }, 3000);

            } else {
                setError(data?.error.message);
                setTimeout(() => {
                    setError("");
                }, 4000);
            }
        } catch (error) {
            setError(error.message);
            setTimeout(() => {
                setError("");
            }, 4000);
        } finally {
            setLoader(false);
            setSubmitting(false);
        }
    };




    useEffect(() => {
        axios.get(`${BASE_URL}/categories`)
            .then(response => {
                setCategory(response.data.data);
            })
            .catch(error => {
                console.error('Error fetching categories: ', error);
            });
    }, []);


    const formikRef = useRef(null);


    const overrideStyle = {
        display: 'flex',
        margin: '0 auto',
        height: '24px',
        justifyContent: 'center',
        alignItem: 'center',
    }

    const handleCloseModal = () => {
        const closeButton = document.getElementById('closeSuggestionsButton');
        if (closeButton) {
            closeButton.click();
        }
        if (formikRef.current) {
            formikRef.current.resetForm();
        }
    };

    return (
        <div className="modal fade" id="SuggestionsPopup" tabIndex="-1" aria-labelledby="Suggestions" aria-hidden="true">

            <div className="modal-dialog modal-lg p-5">
                <div className="modal-content">
                    <div className="modal-body">


                        <button id="closeSuggestionsButton" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseModal}></button>

                        <h3 className='fs-5 text-blue fw-medium m-0'>Suggestions Form</h3>
                        <Formik
                            innerRef={formikRef}
                            initialValues={{
                                name: "",
                                mobile_number: "",
                                category: "",
                                profession: "",
                                suggestion: "",
                                toggle: false,
                                error: "",
                            }}
                            validationSchema={Yup.object({
                                name: Yup.string()
                                    .required("Name is required"),
                                mobile_number: Yup.string()
                                    .required("Mobile Number is Required"),
                                suggestion: Yup.string()
                                    .required('Suggestion is required'),
                            })}
                            onSubmit={handleSubmit}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                setFormikState,
                            }) => (

                                <form
                                    className="form-style"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        handleSubmit();
                                    }}
                                >
                                    <div className="form-group">
                                        <div className="input-container">
                                            <div className="row">
                                                <div className="col-md-6">

                                                    <label className="mt-3">Name</label>
                                                    <input
                                                        type="name"
                                                        id="name"
                                                        className="form-control"
                                                        placeholder="Enter Name"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.name}
                                                        required
                                                    />

                                                </div>

                                                <div className="col-md-6">
                                                    <label className="mt-3">Mobile Number</label>
                                                    <input
                                                        pattern="[0-9]{10}"
                                                        title="Please enter a valid 10-digit phone number"
                                                        type="tel"
                                                        id="mobile_number"
                                                        className="form-control"
                                                        placeholder="Mobile Number"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.mobile_number}
                                                        required
                                                    />
                                                </div>


                                            </div>
                                        </div>
                                    </div>


                                    <div className="form-group">
                                        <div className="input-container">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor="category" className="mt-3">Category</label>
                                                    <select
                                                        className="form-control"
                                                        id="category"
                                                        onChange={handleChange}
                                                        value={values.category}
                                                    >
                                                        <option value=''>Select a category</option>
                                                        {category.map((cat) => (
                                                            <option key={cat.id} value={cat.category}>
                                                                {cat.category}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className="col-md-6">
                                                    <label className="mt-3">Profession</label>
                                                    <input
                                                        type="text"
                                                        id="profession"
                                                        className="form-control"
                                                        placeholder="Enter Profession"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.profession}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="form-group">
                                        <div className="input-container">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <label className="mt-3">Suggestion Box</label>
                                                    <textarea
                                                        type="text"
                                                        id="suggestion"
                                                        className="form-control"
                                                        placeholder="Enter Suggestion"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.suggestion}
                                                        required
                                                    />
                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                    <button disabled={loader ? true : false} className={`btn btn-primary ${loader ? "pb-4" : ""} mt-4`} type='submit'>
                                        {
                                            loader ? <PropagateLoader color='#fff' cssoverride={overrideStyle} /> : 'Submit'
                                        }
                                    </button>
                                    <ToastContainer />


                                    {error && (
                                        <div
                                            className={
                                                success
                                                    ? "col-sm-12 col-md-12 alert alert-success text-center mt-2 text-capitalize"
                                                    : "col-sm-12 col-md-12 alert alert-danger text-center mt-2 text-capitalize"
                                            }
                                        >
                                            {error}
                                        </div>
                                    )}


                                    {success && (
                                        <div
                                            className={
                                                success
                                                    ? "col-sm-12 col-md-12 alert alert-success text-center mt-2 text-capitalize"
                                                    : "col-sm-12 col-md-12 alert alert-danger text-center mt-2 text-capitalize"
                                            }
                                        >
                                            {success}
                                        </div>
                                    )}

                                    {touched.name && errors.name ? (
                                        <div className={
                                            success
                                                ? "col-sm-12 col-md-12 alert alert-success text-center mt-2 text-capitalize"
                                                : "col-sm-12 col-md-12 alert alert-danger text-center mt-2 text-capitalize"
                                        }>{errors.name}</div>
                                    ) : null}

                                    {touched.mobile_number && errors.mobile_number ? (
                                        <div className={
                                            success
                                                ? "col-sm-12 col-md-12 alert alert-success text-center mt-2 text-capitalize"
                                                : "col-sm-12 col-md-12 alert alert-danger text-center mt-2 text-capitalize"
                                        }>{errors.mobile_number}</div>
                                    ) : null}

                                    {touched.suggestion && errors.suggestion ? (
                                        <div className={
                                            success
                                                ? "col-sm-12 col-md-12 alert alert-success text-center mt-2 text-capitalize"
                                                : "col-sm-12 col-md-12 alert alert-danger text-center mt-2 text-capitalize"
                                        }>{errors.suggestion}</div>
                                    ) : null}

                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SuggestionFormModal
