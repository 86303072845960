import React from "react";
import styles from "./Dashboard.module.css"
import axios from "axios";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { SERVER_URL } from "../../config";
const BASE_URL = SERVER_URL;

const Dashboard = () =>  {
	let [ quizCounts, setQuizCounts] =  useState( 0);

	let [ subjectResults, setSubjectResults] =  useState([]);

	let [ activity, setActivity] =  useState( {});

	let [ historyDates, setHistoryDates] =  useState([]);
	let [ activities, setActivities] =  useState([]);



	// handle 429 too many request issue by using Request Queue
	class RequestQueue {
		constructor() {
			this.queue = [];
			this.isProcessing = false;
		}

		enqueue(requestFunction) {
			this.queue.push(requestFunction);
			this.processQueue();
		}

		async processQueue() {
			if (this.isProcessing) return;

			this.isProcessing = true;

			while (this.queue.length > 0) {
				const requestFunction = this.queue.shift();
				await requestFunction();
			}

			this.isProcessing = false;
		}
	}

	const requestQueue = new RequestQueue();

	const getData = async () => {
		return new Promise((resolve, reject) => {
			requestQueue.enqueue(async () => {
				try {
					const token = await localStorage.getItem('token');
					const response = await axios.get(`${BASE_URL}/dashboard`, {
						headers: {
							'remember-token': token,
						}
					});

                    // console.log(response?.data?.data);
					setQuizCounts(response?.data?.data);
					setActivity(response?.data?.data.activity);
					setHistoryDates(response?.data?.data?.history.historyDates);
					setActivities(response?.data?.data?.history.activities);
					setSubjectResults(response?.data?.data?.subject_result);

					resolve(response.data);
				} catch (error) {
					if (error.response?.status === 429) {
						console.warn('429 Too Many Requests. Retrying in 1 second...');
						setTimeout(() => {
							getData().then(resolve).catch(reject);
						}, 1000);
					} else {
						reject(new Error(error.response?.data?.message || error.message));
					}
				}
			});
		});
	};

	const convertSecondsToMinutes = (seconds) => {
        // return (seconds / 60).toFixed(2); // toFixed(2) to keep 2 decimal places
		const minutes = Math.floor(seconds / 60);
		const remainingSeconds = seconds % 60;
		return `${minutes}:${remainingSeconds}`;
    };

	const convertSecondsToMinutesAndSeconds = (seconds) => {
		const minutes = Math.floor(seconds / 60);
		const remainingSeconds = seconds % 60;
		return `${minutes} Minutes ${remainingSeconds} Seconds`;
	};
	
	// Example usage

	useEffect (() =>{
		getData();
		
	},[])
  return (
	<>
		{/* <DashboardPage/> */}
		<div className="container-fluid">
				<h1 className="fs-4 text-dark mb-4">Welcome to DigitalEduPark</h1>

				<div className="row g-4">
					<div className="col-md-4 col-lg-3">
						<div className="card card-body bg-white border-0 rounded-3 p-lg-4">
							<div className="d-flex align-items-center gap-3">
								<div className="col">
									<p className="mb-0 text-muted">All Quizzes</p>
									<p className="mb-0 fs-3 fw-semibold text-dark">{quizCounts.quiz_counts ?? 0}</p>
								</div>
								<figure className={`mb-0 rounded-3 d-flex justify-content-center align-items-center ${styles.icon__box}`}>
									<img src="/assets/images/home/quizes.png" width={48} height={48} alt="icon" />
								</figure>
							</div>
						</div>
					</div>
					{/* <div className="col-md-4 col-lg-3">
						<div className="card card-body bg-white border-0 rounded-3 p-lg-4">
							<div className="d-flex align-items-center gap-3">
								<div className="col">
									<p className="mb-0 text-muted">Test Attended</p>
									<p className="mb-0 fs-3 fw-semibold text-dark">30/50</p>
								</div>
								<figure className={`mb-0 rounded-3 d-flex justify-content-center align-items-center ${styles.icon__box}`}>
									<img src="/assets/images/home/test-attended.svg" width={48} height={48} alt="icon" />
								</figure>
							</div>
						</div>
					</div> */}
					{/* <div className="col-md-4 col-lg-3">
						<div className="card card-body bg-white border-0 rounded-3 p-lg-4">
							<div className="d-flex align-items-center gap-3">
								<div className="col">
									<p className="mb-0 text-muted">Test Passed</p>
									<p className="mb-0 fs-3 fw-semibold text-dark">15/20</p>
								</div>
								<figure className={`mb-0 rounded-3 d-flex justify-content-center align-items-center ${styles.icon__box}`}>
									<img src="/assets/images/home/test-passed.svg" width={48} height={48} alt="icon" />
								</figure>
							</div>
						</div>
					</div> */}

					{subjectResults.map((result, index) => (
						<div className="col-md-4 col-lg-3" key={index}>
							<div className="card card-body bg-white border-0 rounded-3 p-lg-4">
								<div className="d-flex align-items-center gap-3">
									<div className="col">
										<p className="mb-0 text-muted">{result.subject ?? ""}</p>
										<p className="mb-0 fs-3 fw-semibold text-dark">{result['total-quiz'] ?? 0}</p>
									</div>
									<figure className={`mb-0 rounded-3 d-flex justify-content-center align-items-center ${styles.icon__box}`}>
										<img src="/assets/images/home/test-attended.svg" width={48} height={48} alt="icon" />
									</figure>
								</div>
							</div>
						</div>
					))}
				</div>
				{/* row */}

				<div className="row g-4 mt-4">
					<div className="col-lg-6 d-flex">
						<div className={`${styles.card} card w-100`}> 
							<div className="card-body p-md-4">
								<h2 className="fs-5 mb-4">Today's Activity</h2>
								<div className="row g-3">
									<div className="col-lg-3 col-md-3 dash-widget1">
										<div className="circle-bar circle-bar2 d-inline-block position-relative text-center">
											
											<svg viewBox="0 0 36 36" className={styles.circular_chart}>
												<path className={styles.circle_bg}
													d="M18 2.0845
													a 15.9155 15.9155 0 0 1 0 31.831
													a 15.9155 15.9155 0 0 1 0 -31.831"
												/>
												<path className={styles.circle}
													strokeDasharray={`${activity.percentage ?? 0}, 100`}
													d="M18 2.0845
													a 15.9155 15.9155 0 0 1 0 31.831
													a 15.9155 15.9155 0 0 1 0 -31.831"
												/>
												<text x="8" y="22" className={styles.percentage}>{activity.percentage ?? 0}%</text>
											</svg> 
										</div>
									</div>
									<div className="col-lg-4 col-md-4">
										<div className="d-flex flex-column gap-3">
											<div className="d-flex gap-3 lh-sm">
												<div className="lesson-imgs">
													<img src="assets/images/home/lesson-icon-01.svg" alt="img"/>
												</div>
												<div className="views-lesson">
													<span className="d-block small text-muted ">Class</span>
													<p className="mb-0 fs-6 text-dark fw-semibold">{activity.class ?? ""}</p>
												</div>
											</div>
											<div className="d-flex gap-3 lh-sm">
												<div className="lesson-imgs">
													<img src="assets/images/home/lesson-icon-02.svg" alt="img"/>
												</div>
												<div className="views-lesson">
													<span className="d-block small text-muted ">Subject</span>
													<p className="mb-0 fs-6 text-dark fw-semibold">{activity.subject ?? ""} Subject</p>
												</div>
											</div>
											<div className="d-flex gap-3 lh-sm">
												<div className="lesson-imgs">
													<img src="assets/images/home/lesson-icon-03.svg" alt="img"/>
												</div>
												<div className="views-lesson">
													<span className="d-block small text-muted ">Time</span>
													<p className="mb-0 fs-6 text-dark fw-semibold">{convertSecondsToMinutesAndSeconds(activity.time ?? 0 ) ?? 0}</p>
												</div>
											</div>
										</div>
									</div>
									{/* <div className="col-lg-4 col-md-4">
										<div className="d-flex flex-column gap-3">
											<div className="d-flex gap-3 lh-sm">
												<div className="lesson-imgs">
													<img src="assets/images/home/lesson-icon-04.svg" alt="img"/>
												</div>
												<div className="views-lesson">
													<span className="d-block small text-muted ">Asignment</span>
													<p className="mb-0 fs-6 text-dark fw-semibold">5 Asignment</p>
												</div>
											</div>
											<div className="d-flex gap-3 lh-sm">
												<div className="lesson-imgs">
													<img src="assets/images/home/lesson-icon-05.svg" alt="img"/>
												</div>
												<div className="views-lesson">
													<span className="d-block small text-muted ">Staff</span>
													<p className="mb-0 fs-6 text-dark fw-semibold">John Doe</p>
												</div>
											</div>
											<div className="d-flex gap-3 lh-sm">
												<div className="lesson-imgs">
													<img src="assets/images/home/lesson-icon-06.svg" alt="img"/>
												</div>
												<div className="views-lesson">
													<span className="d-block small text-muted ">Lesson Learned</span>
													<p className="mb-0 fs-6 text-dark fw-semibold">10/50</p>
												</div>
											</div>
										</div>
									</div>  */}
								</div>
							</div>
						</div>
						{/* card */}

					</div>
					{/* <div className="col-lg-6 d-flex"> 
						<div className={`${styles.card} card w-100`}> 
							<div className="card-body p-md-4">
								<h2 className="fs-5 mb-4">Student History</h2>
								<div className="teaching-card d-flex">
									<ul className={styles.steps_history}>
										<li>Sep22</li>
										<li>Sep23</li>
										<li>Sep24</li>
									</ul>
									<ul className={styles.activity_feed}>
										<li className={`d-flex flex-wrap gap-2 align-items-center ${styles.feed_item}`}>
											<div className="dolor-activity">
												<span className="feed-text1"><Link>Mathematics</Link></span>
												<ul className={styles.teacher_date_list}>
													<li><i className="fas fa-calendar-alt me-2"></i>September 5, 2022</li>
													<li>|</li>
													<li><i className="fas fa-clock me-2"></i>09:00 am - 10:00 am (60 Minutes)</li>
												</ul>
											</div>
											<div className={`ms-auto ${styles.activity_btns}`}>
												<button type="submit" className={`btn py-2 ${styles.btn_info}`}>In Progress</button>
											</div>
										</li>
										<li className={`d-flex flex-wrap gap-2 align-items-center ${styles.feed_item}`}>
											<div className="dolor-activity">
												<span className="feed-text1"><Link>Geography	</Link></span>
												<ul className={styles.teacher_date_list}>
													<li><i className="fas fa-calendar-alt me-2"></i>September 5, 2022</li>
													<li>|</li>
													<li><i className="fas fa-clock me-2"></i>09:00 am - 10:00 am (60 Minutes)</li>
												</ul>
											</div>
											<div className={`ms-auto ${styles.activity_btns}`}>
												<button type="submit" className={`btn py-2 ${styles.btn_info}`}>Completed</button>
											</div>
										</li>
										<li className={`d-flex flex-wrap gap-2 align-items-center ${styles.feed_item}`}>
											<div className="dolor-activity">
												<span className="feed-text1"><Link>Botony</Link></span>
												<ul className={styles.teacher_date_list}>
													<li><i className="fas fa-calendar-alt me-2"></i>September 5, 2022</li>
													<li>|</li>
													<li><i className="fas fa-clock me-2"></i>09:00 am - 10:00 am (60 Minutes)</li>
												</ul>
											</div>
											<div className={`ms-auto ${styles.activity_btns}`}>
												<button type="submit" className={`btn py-2 ${styles.btn_info}`}>In Progress</button>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div> */}

					<div className="col-lg-6 d-flex">
						<div className={`${styles.card} card w-100`}>
							<div className="card-body p-md-4">
								<h2 className="fs-5 mb-4">Student History</h2>
								<div className="teaching-card d-flex">
									<ul className={styles.steps_history}>
										{historyDates.map(date => (
											<li key={date}>{date}</li>
										))}
									</ul>
									<ul className={styles.activity_feed}>
										{activities.map((activity, index) => (
											<li
												key={index}
												className={`d-flex flex-wrap gap-2 align-items-center ${styles.feed_item}`}
											>
												<div className="dolor-activity">
													<span className="feed-text1">
														{activity.subject ?? ""}
													</span>
													<ul className={styles.teacher_date_list}>
														<li>
															<i className="fas fa-calendar-alt me-2"></i>
															{activity.date ?? ""}
														</li>
														<li>|</li>
														<li>
															<i className="fas fa-clock me-2"></i>
															{activity.time} ({convertSecondsToMinutesAndSeconds(activity.duration ?? 0 ) ?? 0})
														</li>
													</ul>
												</div>
												<div className={`ms-auto ${styles.activity_btns}`}>
													<button
														type="button"
														className={`btn py-2 ${styles.btn_info}`}
													>
														{activity.status}
													</button>
												</div>
											</li>
										))}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* row */}
				 

				<div className="col-lg-6 d-flex mt-4">
					<div className="notice-board card border-0 w-100">
						<div className="card-header bg-white py-3">
							<h2 className="fs-5 text-blue fw-medium m-0">Notice Board</h2>
						</div>
						<div className="card-body">
							<div className="col-lg-12 notice-container">
								<Link to="#" className="notice-card d-flex rounded-2 align-items-center" style={{ '--date-bg': '#C7EFFC', '--card-bg': '#EBFAFF' }}>
									<div className="notice-date fs-3 fw-medium text-dark p-3 text-center rounded-1 lh-1">
										Jan 26
									</div>
									<div className="col ps-3">
										<p className="fs-15 mb-0">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled</p>
									</div>
								</Link>
								<Link to="#" className="notice-card d-flex rounded-2 align-items-center" style={{ '--date-bg': '#FFEFB9', '--card-bg': '#FFF7DD' }}>
									<div className="notice-date fs-3 fw-medium text-dark p-3 text-center rounded-1 lh-1">
										Jan 28
									</div>
									<div className="col ps-3">
										<p className="fs-15 mb-0">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled</p>
									</div>
								</Link>
								<Link to="#" className="notice-card d-flex rounded-2 align-items-center" style={{ '--date-bg': '#d4ffb9', '--card-bg': '#efffdd' }}>
									<div className="notice-date fs-3 fw-medium text-dark p-3 text-center rounded-1 lh-1">
										Jan 30
									</div>
									<div className="col ps-3">
										<p className="fs-15 mb-0">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled</p>
									</div>
								</Link>
							</div>
						</div>
					</div>
				</div>

			</div>
		
			{/* container */}

		</>
  )
}

export default Dashboard;
