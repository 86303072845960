import React from "react";
import { useState,useEffect } from "react";
import SearchIcon from "../asset/images/icons/search.svg";
import ChevronImg from "../asset/images/icons/chevron-down.svg";
import GlobeWhite from "../asset/images/icons/globe-white.svg";
import StudentCapWhite from "../asset/images/icons/student-cap-white.svg";
import ExamWhite from "../asset/images/icons/exam-white.svg";
import TeamWhite from "../asset/images/icons/team-white.svg";
import axios from "axios";
import { Link } from "react-router-dom";
import { SERVER_URL } from "../config";
const BASE_URL = SERVER_URL;

const Test = () => {

	const initialUser = localStorage.getItem("token") || null;
	const [user, setUser] = useState(initialUser !== "null" ? initialUser : null);

	const [searchQuery, setSearchQuery] = useState('');
	const [categoryData , setCategoryData] = useState([]);

		useEffect(() => 
		{
				axios.get(`${BASE_URL}/categories-class`)
				.then(response => 
					{
						setCategoryData(response.data.data);
					})
				.catch(error => {
						console.error('Error fetching data: ', error);
				});
		}, 
		[]);


	
	useEffect(() => {

		const token = localStorage.getItem("token");
		setUser(token || null);
	}, [initialUser]);

	const [filteredCategory, setFilteredCategory] = useState([]);

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);

        // Filter the category data based on the search query
        if (query.trim() !== '') {
            const filtered = categoryData.map(category => ({
                ...category,
                class: category.class.filter(cls => cls.class_name.toLowerCase().includes(query.toLowerCase()))
            })).filter(category => category.class.length > 0);
            
            setFilteredCategory(filtered);
        } else {
            setFilteredCategory([]);
        }
    };

	return (
		<section className="py-5 bg-blue">
			<div className="container py-sm-4">
				<h2 className="text-center fs-1 fw-semibold text-white mb-4 mb-lg-5">Online Tests & Quizzes</h2>

				<div className="col-lg-10 mx-auto">
					<form action="#" className="row g-3 quizzes__form">
						{/* <div className="col-md-4">
							<div className="position-relative">
								<img
									src={SearchIcon}
									width="20" height="20" alt="search"
									className="position-absolute top-50 translate-middle-y"
									style={{ 'left': '1rem' }}
								/>
								<select name="quzzes" id="quzzes" className="form-select"
									style={{ 'backgroundImage': `url(${ChevronImg})` }}
								>
									<option value="Select Quizzes">Select Quizzes</option>
								</select>
							</div>
						</div> */}

							{/* <div className="col-md-3">
								<button className="btn btn-primary" onChange={handleSearchChange}> 
									<span>Search</span>
								</button>
							</div> */}


						{
							user ? 
							<>
							
							</> :
							<>
							<div className="col-md-5">
								<input
									type="text"
									name="search"
									className="form-control"
									placeholder="Search for a Quizzes or Exams"
									value={searchQuery}
									onChange={handleSearchChange}
									style={{ backgroundImage: `url(${SearchIcon})` }}
								/>
							</div>
							
						
							{searchQuery.trim() !== '' && (
								<div className="search-results-box" style={{ border: '1px solid #ccc', marginTop: '10px', padding: '10px', borderRadius: '5px', maxHeight: '200px', overflowY: 'auto' }}>
									{filteredCategory.length > 0 ? (
										filteredCategory.map((category, catIndex) => (
											category.class.map((cls, index) => (
												<Link
													key={`${catIndex}-${index}`}
													className="search-result-item"
													to={`/classes/${cls.class_id}`}
													style={{ display: 'block', padding: '5px', textDecoration: 'none', color: '#000' }}
												>
													{cls.class_name}
												</Link>
											))
										))
									) : (
										<div style={{ textAlign: "center" }}>No data available</div>
									)}
								</div>
							)}
							</>
						}
						
						
					</form>
				</div>

				<div className="row g-4 mt-3">
					<div className="col-xl-3 col-md-6 col-sm-6">
						<div className="d-flex quizze__info__card gap-3 align-items-center">
							<figure className="icon mb-0 rounded-2 d-flex justify-content-center align-items-center">
								<img
									src={StudentCapWhite}
									width="32" height="32" alt="cap"
									className="img-fluid" loading="lazy"
								/>
							</figure>
							<div className="col">
								<p className="text-white mb-0 lh-1_25">Trusted by over 1,200 students and teachers</p>
							</div>
						</div>
					</div>
					<div className="col-xl-3 col-md-6 col-sm-6">
						<div className="d-flex quizze__info__card gap-3 align-items-center">
							<figure className="icon mb-0 rounded-2 d-flex justify-content-center align-items-center">
								<img
									src={ExamWhite}
									width="32" height="32" alt="courses"
									className="img-fluid" loading="lazy"
								/>
							</figure>
							<div className="col">
								<p className="text-white mb-0 lh-1_25">Over 2,500 online courses available every day</p>
							</div>
						</div>
					</div>
					<div className="col-xl-3 col-md-6 col-sm-6">
						<div className="d-flex quizze__info__card gap-3 align-items-center">
							<figure className="icon mb-0 rounded-2 d-flex justify-content-center align-items-center">
								<img
									src={GlobeWhite}
									width="32" height="32" alt="country"
									className="img-fluid" loading="lazy"
								/>
							</figure>
							<div className="col">
								<p className="text-white mb-0 lh-1_25">Our students come from every country in the world</p>
							</div>
						</div>
					</div>
					<div className="col-xl-3 col-md-6 col-sm-6">
						<div className="d-flex quizze__info__card gap-3 align-items-center">
							<figure className="icon mb-0 rounded-2 d-flex justify-content-center align-items-center">
								<img
									src={TeamWhite}
									width="32" height="32" alt="team"
									className="img-fluid" loading="lazy"
								/>
							</figure>
							<div className="col">
								<p className="text-white mb-0 lh-1_25">Our team is devoted to transforming education</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
export default Test;