import React from 'react'
import BlogHeader from './BlogHeader'
import { Link } from 'react-router-dom'
import styles from './Blog.module.css'
import Pagination from './Pagination'

const data = [
    {
        thumbnail: "/assets/images/blog/blog-2.png",
        title: "Successful Online Completive Exams",
        description: "Cras congue, sapien sed mollis accumsan, justo orci pulvinar nisl, ut fermentum ante velit in ante. Vivamus blandit urna urna..."
    },
    {
        thumbnail: "/assets/images/blog/blog-3.png",
        title: "Different Kinds Of Online Educations",
        description: "Cras congue, sapien sed mollis accumsan, justo orci pulvinar nisl, ut fermentum ante velit in ante. Vivamus blandit urna urna..."
    },
    {
        thumbnail: "/assets/images/blog/blog-2.png",
        title: "Successful Online Completive Exams",
        description: "Cras congue, sapien sed mollis accumsan, justo orci pulvinar nisl, ut fermentum ante velit in ante. Vivamus blandit urna urna..."
    },
    {
        thumbnail: "/assets/images/blog/blog-3.png",
        title: "Different Kinds Of Online Educations",
        description: "Cras congue, sapien sed mollis accumsan, justo orci pulvinar nisl, ut fermentum ante velit in ante. Vivamus blandit urna urna..."
    },
    {
        thumbnail: "/assets/images/blog/blog-2.png",
        title: "Successful Online Completive Exams",
        description: "Cras congue, sapien sed mollis accumsan, justo orci pulvinar nisl, ut fermentum ante velit in ante. Vivamus blandit urna urna..."
    },
    {
        thumbnail: "/assets/images/blog/blog-3.png",
        title: "Different Kinds Of Online Educations",
        description: "Cras congue, sapien sed mollis accumsan, justo orci pulvinar nisl, ut fermentum ante velit in ante. Vivamus blandit urna urna..."
    },
     
]

const Blog = () => {
  return (
    <>
        <BlogHeader />  
        <div className="py-5 position-relative z-3">
            <div className="container">
                <div className="col-lg-10 col-xxl-10 mx-auto">
                    <div className="row g-4 gy-md-5">
                        <div className="col-12">
                            <div className="hero__blog">
                                <Link to={"#"} className="d-block overflow-hidden rounded-3">
                                    <img src="/assets/images/blog/blog-1.jpg" className={`img-fluid w-100 object-fit-cover rounded-3 ${styles.hero__blog__thumbnail}`} alt="blog-thumbnail" />
                                </Link>
                                <div className="pt-3">
                                    <p className={`${styles.post__type} mb-2`}>BLOG POST</p>
                                    <h2 className="fs-4 fw-semibold">
                                        <Link to={"#"} className="link">Different Kinds Of Online Educations</Link>
                                    </h2>
                                    <p className={styles.description}>
                                        Cras congue, sapien sed mollis accumsan, justo orci pulvinar nisl, ut fermentum ante velit in ante. Vivamus blandit urna urna, quis tincidunt turpis sagittis ut. In interdum sem quis congue accumsan. Pellentesque convallis scelerisque eros.
                                    </p>
                                    <div>
                                        <Link to={"#"} className={`${styles.link} d-inline-flex align-items-center gap-2 fs-5 fw-semibold`}>
                                            <span>Read more</span>
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.57227 7.05711H13.0008M13.0008 7.05711L7.51509 1.57141M13.0008 7.05711L7.51509 12.5428" stroke="#00A4D8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg> 
                                        </Link>
                                    </div>
                                </div>
                            </div> 
                        </div>
                        {/* col */}


                        {
                            data.map((item, index) => {
                                return ( 
                                    <div key={index} className="col-md-6">
                                        <div className="hero__blog">
                                            <Link to={"#"} className="d-block overflow-hidden rounded-3">
                                                <img src={item.thumbnail} className={`img-fluid w-100 object-fit-cover rounded-3 ${styles.blog__thumbnail}`} alt="blog-thumbnail" />
                                            </Link>
                                            <div className="pt-3">
                                                <p className={`${styles.post__type} mb-2`}>BLOG POST</p>
                                                <h2 className="fs-4 fw-semibold">
                                                    <Link to={"#"} className="link">{item.title}</Link>
                                                </h2>
                                                <p className={styles.description}>
                                                    {item.description}
                                                </p>
                                                <div>
                                                    <Link to={"#"} className={`${styles.link} d-inline-flex align-items-center gap-2 fs-5 fw-semibold`}>
                                                        <span>Read more</span>
                                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1.57227 7.05711H13.0008M13.0008 7.05711L7.51509 1.57141M13.0008 7.05711L7.51509 12.5428" stroke="#00A4D8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg> 
                                                    </Link>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                )
                            })
                        }
                        {/* col */}


                    </div>
                    {/* row */}







                </div>

                <div className="mt-5">
                    <Pagination />
                </div>
            </div>
            {/* container */}
        </div> 
    </>
  )
}

export default Blog