import React from 'react'
import Header from './Header/Header'
import { Outlet } from 'react-router'

const ExamLayout = () => {
  return (
    <div>
        <div className="col instructions-top-container">
            <Header /> 
            <div className=""> 
                <Outlet />
            </div> 
                
        </div> 
    </div>
  )
}

export default ExamLayout