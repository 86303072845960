import React from 'react'

const Pagination = () => {
    return (
        <div>
            <nav aria-label="Page navigation example">
                <ul className="pagination custom__pagination justify-content-center">
                    <li className="page-item">
                        <a className="page-link p-1 border-0" href="#" aria-label="Previous">
                            <svg xmlns="http://www.w3.org/2000/svg" width="0.48em" height="1em" viewBox="0 0 608 1280"><path fill="black" d="M595 288q0 13-10 23L192 704l393 393q10 10 10 23t-10 23l-50 50q-10 10-23 10t-23-10L23 727q-10-10-10-23t10-23l466-466q10-10 23-10t23 10l50 50q10 10 10 23"/></svg>
                        </a>
                    </li>
                    <li className="page-item active"><a className="page-link p-1 border-0" href="#">1</a></li>
                    <li className="page-item"><a className="page-link p-1 border-0" href="#">2</a></li>
                    <li className="page-item"><a className="page-link p-1 border-0" href="#">3</a></li>
                    <li className="page-item">
                        <a className="page-link p-1 border-0" href="#" aria-label="Next">
                            <svg xmlns="http://www.w3.org/2000/svg" width="0.48em" height="1em" viewBox="0 0 608 1280"><g transform="translate(608 0) scale(-1 1)"><path fill="black" d="M595 288q0 13-10 23L192 704l393 393q10 10 10 23t-10 23l-50 50q-10 10-23 10t-23-10L23 727q-10-10-10-23t10-23l466-466q10-10 23-10t23 10l50 50q10 10 10 23"/></g></svg>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default Pagination