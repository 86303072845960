const aboutBanner=()=>{
  return (<>
            <section className="about-banner clearfix text-center">
              <div className="container">
                <h1>Who We Are</h1>
                <p>Our secure, flexible &amp; easy to use website allows you to book our private airport transfer in just a few clicks. Whether you are arriving in the city or heading off to the airport, get our private chauffeurs at your doorsteps and ensure a luxurious transfer.</p>
              </div>
            </section>	
    </>);
} 
export default aboutBanner;