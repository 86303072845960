import React from 'react'
import styles from './Blog.module.css'

const BlogHeader = () => {
  return (
    <div className={`${styles.header__wrapper} position-relative`}>
        <div className="container position-relative z-3">
            <h1 className="text-center display-5 fw-bold text-primary ">
                <span className="text-blue d-block">Success stories,</span>
                blogs and reflections
            </h1>
        </div>
        <img 
            src="/assets/images/blog/blog-header-bg-image.png" 
            alt="Header Bg Image" 
            className={`position-absolute start-0 object-fit-cover img-fluid w-100 z-0 ${styles.bg__image}`}
        />
    </div>
  )
}

export default BlogHeader