import React from 'react'
import Navbar from '../layouts/Navbar'
import Clasess from '../class/classes'
import Footer from '../layouts/Footer'
import ClassHeader from '../class/ClassHeader'
function FAQs() {
  return (
        <> 
        <ClassHeader/>
        <Clasess/> 
        </>
  )
}

export default FAQs
