import React, { lazy, Suspense }  from 'react'
import Header from './Header'
import Footer from './Footer'
import { Outlet } from 'react-router'
// import Navbar from './Navbar'
const Navbar = lazy(() => import("./Navbar.jsx"));
const Layout = () => {
  return (
    <>
    <Suspense fallback={<div>Loading...</div>}>
        <Navbar />
        <main>
            <Outlet />
        </main>
        <Footer />
    </Suspense>
    </>
  )
}

export default Layout