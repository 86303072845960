import React, { useState, useEffect } from 'react';
import axios from 'axios'; // If using axios
import Header from '../layouts/Header/Header';
import Sidebar from '../layouts/Sidebar/Sidebar';
import userProfile from '../assets/img/profiles/profile/User-Profile-PNG-Image.png';
import { SERVER_URL } from "../../config";
import './userProfile.css';
import { PropagateLoader } from 'react-spinners';



const BASE_URL = SERVER_URL;

function UserProfile() {
  const [profile, setProfile] = useState({
    full_name: '',
    email: '',
    user_name: '',
    contact_number: '',
    address: '',
    zipcode: '',
    gender: ''

  });
  const [error, setError] = useState('');

  const [profilePic, setProfilePic] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [profilePicName, setProfilePicName] = useState(null);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError('User is not authenticated');
      return;
    }

    axios.get(`${BASE_URL}/student-details`, {
      headers: {
        'remember-token': token,
      }
    })
      .then(response => {

        if (response.data.status) {

          setProfile(response.data.data);
          setPreviewUrl(response.data.data.profile);

          let profile = response.data.data.profile;
          let imageName = profile.split('/').pop();
          setProfilePicName(imageName);
          setError(''); // Clear any previous errors
        }
      })
      .catch(error => {
        console.error('There was an error fetching the data!', error);
        setError('No Data Found.');
      });
  }, []);

  const handleInputChange = (e) => {

    const { name, value } = e.target;
    setProfile(prevProfile => ({
      ...prevProfile,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePic(file);
      setProfilePicName(file.name);
      const url = URL.createObjectURL(file);
      setPreviewUrl(url);
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('User is not authenticated');
        return;
      }

      const formData = new FormData();
      formData.append('name', profile.full_name);
      formData.append('email', profile.email);
      formData.append('user_name', profile.user_name);
      formData.append('contact_number', profile.contact_number || 0);
      formData.append('address', profile.address || null);
      formData.append('zipcode', profile.zipcode || 0);
      formData.append('profile_pic', profilePic);
      formData.append('gender', profile.gender);

      const response = await axios.post(`${BASE_URL}/profile`, formData, {
        headers: {
          // 'Content-Type': 'application/json',
          'remember-token': token,
          'Content-Type': 'multipart/form-data',
        },
      });

      

      if (response.data.status) {
        setLoading(false);
        window.location.reload();
        alert('Profile updated successfully');
      } else {
        alert('Failed to update profile');
      }
    } catch (error) {
      console.error('There was an error updating the profile!', error);
      setError('Error updating profile.');
    }finally{
      setLoading(false);
    }
  };
  

  return (
    <>
      {/* <Header />
      <Sidebar /> */}
      <div className="user-profile-main-container">
        <div className="d-flex align-items-center gap-3 flex-wrap justify-content-between mb-4 border-bottom pb-1">
          <h1 className='fs-3 text-blue fw-medium'>Profile Details</h1>
          <div className='textmuted'>Dashboard <span>/ Profile</span></div>
        </div>
        

        <div className="user-profile-details-container">

          <div className="col-lg-8 mx-auto">
            <div className="card border-0 rounded-3 overflow-hidden">
              <div className="card-header py-4 bg-white">
                <h2 className='lead-lg fw-semibold text-blue m-0'>Profile Details</h2>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row g-4">
                    <div className="col-md-6">
                      <label htmlFor="user_name">User Name</label>
                      <input
                        type="text"
                        id="user_name"
                        name="user_name"
                        onChange={handleInputChange}
                        value={profile.user_name}
                        placeholder="Enter user_name"
                        className="form-control"
                        disabled
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="email">Email</label>
                      <input
                        type="text"
                        id="email"
                        name="email"
                        value={profile.email}
                        onChange={handleInputChange}
                        placeholder="Enter email"
                        className="form-control"
                        disabled
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="full_name">Full Name</label>
                      <input
                        type="text"
                        id="full_name"
                        name="full_name"
                        value={profile.full_name}
                        onChange={handleInputChange}
                        placeholder="Enter full name"
                        className="form-control"
                      />
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="contact_number">Contact No.</label>
                      <input
                        type="text"
                        id="contact_number"
                        name="contact_number"
                        value={profile.contact_number}
                        onChange={handleInputChange}
                        placeholder="Enter contact number"
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="address">Address</label>
                      <input
                        type="text"
                        id="address"
                        name="address"
                        value={profile.address}
                        onChange={handleInputChange}
                        placeholder="Enter address"
                        className="form-control"
                      />
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="Zipcode">Zipcode</label>
                      <input
                        type="text"
                        id="Zipcode"
                        name="zipcode"
                        value={profile.zipcode}
                        onChange={handleInputChange}
                        placeholder="Enter zipcode"
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="profilePic" className='d-block'>Upload Profile Picture</label>
                      <input
                        type="file"
                        id="profilePic"
                        name="profilePic"
                        onChange={handleFileChange} 
                        accept="image/*"
                      /> 
                    </div>
 
                    <div className="col-md-6">
                      <label htmlFor="gender">Gender</label>
                      <select
                        type="text"
                        id="gender"
                        onChange={handleInputChange}
                        name="gender"
                        value={profile.gender}
                        placeholder="Enter address"
                        className="form-select"
                      >
                        {/* 0 = male , 1 = female, 2 = other */}
                        <option value="">Select Gender</option>
                        <option value="0">Male</option>
                        <option value="1">Female</option>
                        <option value="2">Other</option>
                      </select>
                    </div>

 
                    <div className="d-flex align-items-center col-xl-12">
                      <div className="d-flex flex-column col-xl-5">

                        {profilePicName && (
                          <p>Image Name: {profilePicName}</p>
                        )}
                        {previewUrl && (
                          <div className="mt-3">
                            <img src={previewUrl} alt="Profile Preview"
                              style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                              className="img-thumbnail"
                            />
                          </div>
                        )}
                      </div>
                    </div>


                    <div className="text-center col-xl-12">
                      {/* <button type="submit" className="btn btn-primary blue">Update Profile</button> */}
                      <button type="submit" className="btn btn-primary blue" disabled={loading}>
                        {loading ? <PropagateLoader color='#fff' style={{
                          width: '70px',
                          display: 'flex',
                          margin: '0 auto',
                          height: '24px',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }} /> : 'Update Profile'}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserProfile;
