import React, { useState, useCallback, useEffect } from "react"; 
import ChartComponent from "../chartComponent/ChartComponent.jsx";
import './result.css'; // Import this if you separate the CSS into an App.css file
// import ResultDatatable from "./resultDatatable.jsx";
// import ResultDatatale from '../exam/resultDatatable.jsx'
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { quizResultReducer, resultReducer } from "./ExamSlice.jsx"; 
import { profileReducer } from "../../components/Auth/UserProfileSlice.jsx";
import { debounce } from 'lodash';
import ReactPaginate from 'react-paginate';
function Result() {

  const location = useLocation();
  const dispatch = useDispatch();
  // useBlockNavigation();
  let { quiz_id } = location.state || {};

  const quizResult = useSelector((state) => state.quizQuestions.quizResult) || [];
  const result = useSelector((state) => state.quizQuestions.result) || [];

  const getQuizResult = useCallback(async () => {
    try {
      dispatch(
        quizResultReducer({
          //user_type: "admin",
          quiz_id: quiz_id,
        })
      );
    } catch (error) {
      console.error("Failed to fetch quiz questions:", error);
    }
  }, [dispatch, quiz_id]);

  // useEffect(() => {
  //   let mounted = true;

  //   if (mounted) {
  //     getQuizResult();
  //   }

  //   return () => {
  //     mounted = false;
  //   };
  // }, [getQuizResult]);


  const getResult = useCallback(async () => {
    try {
      dispatch(
        resultReducer({
          //user_type: "admin",
          quiz_id: quiz_id,
        })
      );
    } catch (error) {
      console.error("Failed to fetch quiz questions:", error);
    }
  }, [dispatch, quiz_id]);

  // useEffect(() => {
  //   let mounted = true;

  //   if (mounted) {
  //     getResult();
  //   }

  //   return () => {
  //     mounted = false;
  //   };
  // }, [getResult]);


  const debouncedGetAllQuestionList = useCallback(debounce(() => {
    getQuizResult();
    getResult();
  }, 300), [getQuizResult, getResult]);

  useEffect(() => {
    debouncedGetAllQuestionList();
    return debouncedGetAllQuestionList.cancel;
  }, [debouncedGetAllQuestionList]);


  let total_no_of_question = result.total_no_of_question ?? 0;
  let no_of_right_answer = result.no_of_right_answer ?? 0;
  let no_of_wrong_answer = result.no_of_wrong_answer ?? 0;
  let no_of_attempt_question = result.no_of_attempt_question ?? 0;
  let no_of_not_attempt_question = result.no_of_not_attempt_question ?? 0;
  let no_of_marked_question = result.no_of_marked_question ?? 0;
  let total_no_marks = result.total_no_marks ?? 0;

  const pieChartData = {
    // labels: [
    //   'Red',
    //   'Blue',
    //   'Yellow',
    //   'green'
    // ],
    datasets: [{
      labels: [
        'Total Questions',
        'Attempted Questions',
        'Not Attempted Questions',
        'Wrong Answers',
        'Right Answers',
        'Marked Questions'
      ],
      data: [total_no_of_question, no_of_attempt_question, no_of_not_attempt_question, no_of_wrong_answer, no_of_right_answer, no_of_marked_question],
      backgroundColor: [
        "rgba(255, 73, 85, 1)",   // Red for total_no_of_question
        "rgb(255, 205, 86)",      // Yellow for no_of_attempt_question
        "rgb(62, 190, 66)",       // Green for no_of_not_attempt_question
        "rgb(54, 162, 235)",      // Blue for no_of_wrong_answer
        "rgb(155, 89, 182)",      // Purple for no_of_right_answer
        "rgb(255, 192, 203)"      // Pink for no_of_marked_question
      ],
      hoverOffset: 4
    }]
  }

  const [showAllQuestions, setShowAllQuestions] = useState(false);

  const toggleShowAllQuestions = () => {
    setShowAllQuestions(!showAllQuestions);
  };


  const getAllList = useCallback(async () => {
    dispatch(
      profileReducer({
      })
    );
  }, [dispatch]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getAllList();
    }

    return () => {
      mounted = false;
    };
  }, [getAllList]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  
  const [currentPage, setCurrentPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(6);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const handleEntriesChange = (e) => {
    setEntriesPerPage(parseInt(e.target.value));
    setCurrentPage(0);
  };

  
  const offset = currentPage * entriesPerPage;
  const currentResult = quizResult.slice(offset, offset + entriesPerPage);

  return (
    <>
      <div className="overflow-hidden">
        <div className="result-container after__exam pt-5 position-relative">
          <img src="/assets/images/home/ellipse.svg" className="img-fluid position-absolute top-0 end-0 z-0 bottom__curved__icon" alt="curved green circle" />
          <img src="/assets/images/home/assesment-gradient.svg" className="img-fluid position-absolute top-0 gradient__overlay__image z-0" alt="" />

          <div className="container position-relative z-3">
            <div className="row g-4">
              <div className="col-lg-6">
                <div className="card marks__card border-0 rounded-3 overflow-hidden">
                  <div className="card-header bg-white py-3">
                    <h1 className="text-blue m-0 lead-lg fw-medium">Marks Obtained</h1>
                  </div>
                  <div className="card-body">
                    <div className="row g-4">
                      <div className="col-lg-5 col-md-6">
                        <ChartComponent type="pie" data={pieChartData} />
                      </div>
                      <div className="col-lg-7 col-md-6">
                        <div className="row">
                          <div className="col-sm-6">
                            <img src="https://res.cloudinary.com/dp3nahxbi/image/upload/v1717416504/Rectangle_4153252_jxzdq4.png" alt="" />
                            <p className="charts-para">Total Questions: {result.total_no_of_question}</p>
                          </div>
                          <div className="col-sm-6">
                            <img src="https://res.cloudinary.com/dp3nahxbi/image/upload/v1717417294/Rectangle_4153255_iotvyv.png" alt="" />
                            <p className="charts-para">Question Attempted: {result.no_of_attempt_question}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <img src="https://res.cloudinary.com/dp3nahxbi/image/upload/v1717417294/Rectangle_4153253_uopwty.png" alt="" />
                            <p className="charts-para">Question Unattempted: {result.no_of_not_attempt_question}</p>
                          </div>
                          <div className="col-sm-6">
                            <img src="https://res.cloudinary.com/dp3nahxbi/image/upload/v1717417294/Rectangle_4153254_qjifih.png" alt="" />
                            <p className="charts-para">Question Wrong Answered: {result.no_of_wrong_answer}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <i className="fa-solid fa-square" style={{ color: 'purple', fontSize: '21px' }}></i>
                            <p className="charts-para">Question Right Answered: {result.no_of_right_answer}</p>
                          </div>
                          <div className="col-sm-6">
                            <i className="fa-solid fa-square" style={{ color: 'pink', fontSize: '21px' }}></i>
                            <p className="charts-para">Question Marked: {result.no_of_marked_question}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <i className="fa-solid fa-square" style={{ color: 'grey', fontSize: '21px' }}></i>
                            <p className="charts-para">Total Marks: {result.total_no_marks}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card border-0 rounded-3 overflow-hidden">
                  <div className="card-header bg-white py-3 border-0">
                    <h2 className="text-blue m-0 lead-lg fw-medium">Answers Given</h2>
                  </div>
                  <div className="card-body p-0">
                    <table className="table answered__table mb-0 table-striped">
                      <thead>
                        <tr>
                          <th>Answer</th>
                          <th>Percent</th>
                          <th>Image Distance</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Never</td>
                          <td>31.45%</td>
                          <td>+25</td>
                        </tr>
                        <tr>
                          <td>Rarely</td>
                          <td>24.03%</td>
                          <td>+30</td>
                        </tr>
                        <tr>
                          <td>Often</td>
                          <td>24.03%</td>
                          <td>+30</td>
                        </tr>
                        <tr>
                          <td>Often</td>
                          <td>13.03%</td>
                          <td>+65</td>
                        </tr>
                        <tr>
                          <td>Always</td>
                          <td>39.09%</td>
                          <td>+45</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <p className="text-blue fw-medium m-0 lead-lg">Quizzes</p>
                  {/* <p className="text-primary fw-medium m-0 lead" onClick={toggleShowAllQuestions}>
                    {showAllQuestions ? 'Show Less' : 'View All'}
                  </p> */}
                </div>
              </div>

 

            </div>
            {/* row */}

            <div className="row gx-4 gy-3">
              {/* {quizResult.slice(0, showAllQuestions ? quizResult.length : 4).map((questionObj, index) => ( */}
              {currentResult.map((questionObj, index) => (
                <div key={questionObj.result_id} className="col-lg-6 d-flex">
                  <div className="question-container-even w-100">
                    <div className="question-options-container">
                      <p className="question__name m-0 p-3">Question {offset + index + 1}: {questionObj.question}</p>
                      <>
                        {questionObj.image && (
                          <img src={questionObj.image} alt="Question Preview"
                            style={{ width: 'auto', height: '200px', objectFit: 'contain' }}
                            className="img-thumbnail ms-3"
                          />
                        )}
                      </>
                      {questionObj.options.map((option, optIndex) => (
                        <div key={optIndex} className={`option${optIndex + 1} p-3 option__item`}>
                          <input
                            type="radio"
                            className="radio-input"
                            id={`option-${questionObj.result_id}-${optIndex}`}
                            name={`question-${questionObj.result_id}`}
                            value={option}
                            checked={`option${optIndex + 1}` === questionObj.answer}
                            readOnly
                          />
                          <label
                            htmlFor={`option-${questionObj.result_id}-${optIndex}`}
                            style={{
                              color: `option${optIndex + 1}` === questionObj.correct_answer ? 'green' : 'red',
                              fontWeight: `option${optIndex + 1}` === questionObj.correct_answer ? 'bold' : 'normal',
                            }}
                          >
                            <span className="ps-5">{option}</span>
                          </label>
                        </div>
                      ))}
                      <div className="question-result-ans">
                        <p className="result-para-1">Answer Given: {questionObj.answer}</p>
                        <p className="result-para-2">Answer Correct: {questionObj.correct_answer}</p>
                      </div>
                    </div>
                    <div className="Time-taken-container">
                      <p>Time Spent:{" "}{questionObj.duration ?? 0} Sec</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>




            {/* {quizResult.length > 4 && (
                <div className="d-flex justify-content-center mt-4 mt-md-5">
                  <button className="btn btn-primary blue" onClick={toggleShowAllQuestions}>
                    {showAllQuestions ? 'Show Less' : 'View All Questions'}
                  </button>
                </div>
              )} */}


            <div className="mt-3">
              <ReactPaginate
                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={Math.ceil(quizResult.length / entriesPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
              />
            </div>
         


            <div className="card mt-5 border-0 rounded-3 overflow-hidden next-quiz-session-container">
              <div className="card-header py-3 bg-white">
                <p className="text-blue lead-sm fw-medium m-0">Levels According to Time Taken Per Question</p>
              </div>
              <div className="card-body">
                <div className="comming-soon-container text-center py-4">
                  <img src="https://res.cloudinary.com/dp3nahxbi/image/upload/v1717440241/Group_427320156_bvjlrd.png" className="img-fluid" alt="In Progress Image" />
                </div>
              </div>
            </div>

          </div>
          {/* Container */}

          <img src="/assets/images/icons/result-page-bottom-shape.svg" className="img-fluid bottom__curved__icon" alt="curved green circle" />
        </div>

      </div>
    </>
  );
}

export default Result;








